<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">接送站信息</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active"
          >接送站信息</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="card-box">
      <div class="screen-box">
        <div class="screen-input-box">
          <div class="title-box">人员编码:</div>
          <el-input v-model="userCode" placeholder="人员编码" />
        </div>
        <div class="screen-input-box">
          <div class="title-box">人员名称:</div>
          <el-input v-model="userName" placeholder="人员名称" />
        </div>
        <div class="screen-input-box">
          <div class="title-box">护照号:</div>
          <el-input v-model="passportCode" placeholder="护照号" />
        </div>
        <div class="screen-input-box">
          <div class="title-box">国籍:</div>
          <el-select
            v-model="nationality"
            class="card-select"
            placeholder="国籍"
            filterable
            clearable
          >
            <el-option
              v-for="item in Countries"
              :key="item.ID"
              :label="item.CountryNameCHS"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">项目名称:</div>
          <el-select
            v-model="projectID"
            class="card-select"
            placeholder="项目名称"
            filterable
            clearable
          >
            <el-option
              v-for="item in projectList"
              :key="item.ProjectID"
              :label="item.ProjectName"
              :value="item.ProjectID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">用工单位:</div>
          <el-select
            v-model="companyID"
            class="card-select"
            placeholder="用工单位"
            filterable
            clearable
          >
            <el-option
              v-for="item in companyList"
              :key="item.ID"
              :label="item.CompanyCHS"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">飞行路线:</div>
          <el-select
            v-model="airTicketRoute"
            clearable
            placeholder="飞行路线"
            filterable
          >
            <el-option
              v-for="item in ticketGroupList"
              :key="item.ID"
              :label="`${item.DeparatureCityName}——${item.ArrivalCityName}`"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">是否有效机票:</div>
          <el-select
            v-model="active"
            class="card-select"
            placeholder="是否有效机票"
            filterable
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">沙特起飞日期:</div>
          <el-date-picker
            v-model="daterange1"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div class="screen-input-box">
          <div class="title-box">沙特到达日期:</div>
          <el-date-picker
            v-model="daterange2"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <button class="btn-pre" @click="getDropOfforPickup(1)">
          <i class="fa fa-search"></i>查询
        </button>
      </div>
      <div class="table-box">
        <el-tabs v-model="dropOfforPickup" @tab-click="getDropOfforPickupfa">
          <el-tab-pane label="送站信息" name="1">
            <el-table
              :data="tableData"
              height="500"
              border
              tooltip-effect="light"
              :empty-text="loading ? '加载中...' : '暂无数据'"
              :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
            >
              <el-table-column label="#" width="55">
                <template slot-scope="scope">
                  <el-checkbox></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column
                type="index"
                label="序号"
                width="50"
              ></el-table-column>
              <el-table-column
                prop="EmployeeName"
                label="姓名"
                width="80"
              ></el-table-column>
              <el-table-column
                prop="EmployeeNameEN"
                label="英文名"
                width="80"
              ></el-table-column>
              <el-table-column prop="CompanyID	1" label="国籍" width="80">
                <template slot-scope="scope">
                  {{ scope.row.CompanyID | nationalityFormater(Countries) }}
                </template>
              </el-table-column>
              <el-table-column
                prop="PassportCode"
                label="护照号"
                width="100"
              ></el-table-column>
              <el-table-column prop="ProjectID" label="项目名称" width="100">
                <template slot-scope="scope">
                  {{ scope.row.ProjectID | projectFormater(projectList) }}
                </template>
              </el-table-column>

              <el-table-column prop="CreateDate" label="用工单位" width="100">
                <template slot-scope="scope">
                  {{ scope.row.CompanyID | companyFormater(companyList) }}
                </template>
              </el-table-column>

              <el-table-column
                prop="CreateDate"
                label="One Way/Round-Trip"
                width="130"
              >
                <template slot-scope="scope">
                  {{ scope.row.OneWayorRoundTrip | labelFormater(options3) }}
                </template>
              </el-table-column>
              <el-table-column
                prop="AirTicketRoute"
                label="飞行路线"
                width="100"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.AirTicketRoute | ticketFormater(ticketGroupList)
                  }}
                </template>
              </el-table-column>

              <el-table-column
                prop="DeparatureTimefromKSA"
                label="沙特起飞时间"
                width="120"
                sortable
              >
                <template slot-scope="scope">
                  {{ scope.row.DeparatureTimefromKSA | tableDateFrom2 }}
                </template>
              </el-table-column>
              <el-table-column prop="AirlineCode" label="航司信息" width="100">
              </el-table-column>
            </el-table>
            <el-pagination
              @size-change="getDropOfforPickup(1)"
              @current-change="getDropOfforPickup"
              :current-page.sync="page"
              :page-size.sync="size"
              :page-sizes="[10, 20, 50, 100]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination
          ></el-tab-pane>
          <el-tab-pane label="接站信息" name="2">
            <el-table
              :data="tableData"
              height="500"
              border
              tooltip-effect="light"
              :empty-text="loading ? '加载中...' : '暂无数据'"
              :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
            >
              <el-table-column label="#" width="55">
                <template slot-scope="scope">
                  <el-checkbox></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column
                type="index"
                label="序号"
                width="50"
              ></el-table-column>
              <el-table-column
                prop="EmployeeName"
                label="姓名"
                width="80"
              ></el-table-column>
              <el-table-column
                prop="EmployeeNameEN"
                label="英文名"
                width="80"
              ></el-table-column>
              <el-table-column prop="CompanyID	1" label="国籍" width="80">
                <template slot-scope="scope">
                  {{ scope.row.CompanyID | nationalityFormater(Countries) }}
                </template>
              </el-table-column>
              <el-table-column
                prop="PassportCode"
                label="护照号"
                width="100"
              ></el-table-column>
              <el-table-column prop="ProjectID" label="项目名称" width="100">
                <template slot-scope="scope">
                  {{ scope.row.ProjectID | projectFormater(projectList) }}
                </template>
              </el-table-column>

              <el-table-column prop="CreateDate" label="用工单位" width="100">
                <template slot-scope="scope">
                  {{ scope.row.CompanyID | companyFormater(companyList) }}
                </template>
              </el-table-column>

              <el-table-column
                prop="CreateDate"
                label="One Way/Round-Trip"
                width="130"
              >
                <template slot-scope="scope">
                  {{ scope.row.OneWayorRoundTrip | labelFormater(options3) }}
                </template>
              </el-table-column>
              <el-table-column
                prop="AirTicketRoute"
                label="飞行路线"
                width="100"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.AirTicketRoute | ticketFormater(ticketGroupList)
                  }}
                </template>
              </el-table-column>

              <el-table-column
                prop="ArrivalTimeatKSA"
                label="沙特到达时间"
                width="120"
                sortable
              >
                <template slot-scope="scope">
                  {{ scope.row.ArrivalTimeatKSA | tableDateFrom2 }}
                </template>
              </el-table-column>
              <el-table-column prop="AirlineCode" label="航司信息" width="100">
              </el-table-column>
            </el-table>
            <el-pagination
              @size-change="getDropOfforPickup"
              @current-change="getDropOfforPickup"
              :current-page.sync="page"
              :page-size.sync="size"
              :page-sizes="[10, 20, 50, 100]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination
          ></el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { getProjectAll, getCompanyAll } from "@/api/user";
import {
  getDropOfforPickup,
  getGeneralBasic,
  getAirlinesAll,
} from "@/api/table";

export default {
  filters: {
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : null;
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : null;
    },
    labelFormater(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
    ticketFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item
        ? `${item.DeparatureCityName}——${item.ArrivalCityName}`
        : null;
    },
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    tableDateFrom2(data) {
      if (data) {
        data = data.slice(0, 16);
      }
      return data;
    },
    nationalityFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CountryNameCHS : null;
    },
  },
  data() {
    return {
      tableData: [],
      projectList: [],
      companyList: [],
      Countries: [],
      ticketGroupList: [],
      loading: true,
      page: 1,
      size: 10,
      total: null,
      projectID: null,
      companyID: null,
      airTicketRoute: null,
      nationality: null,
      userCode: null,
      userName: null,
      passportCode: null,
      dropOfforPickup: "1",
      active: null,
      daterange1: null,
      daterange2: null,
      options: [
        {
          value: true,
          label: "有效",
        },
        {
          value: false,
          label: "无效",
        },
      ],
      options3: [
        {
          label: "One Way",
          value: 1,
        },
        {
          label: "Round-Trip",
          value: 2,
        },
      ],
    };
  },

  methods: {
    //获取列表
    getDropOfforPickup(type) {
      this.loading = true;
      if (type) {
        this.page = type;
      }
      const data = {
        page: this.page,
        size: this.size,
        projectID: this.projectID,
        companyID: this.companyID,
        airTicketRoute: this.airTicketRoute,
        nationality: this.nationality,
        userCode: this.userCode,
        userName: this.userName,
        passportCode: this.passportCode,
        dropOfforPickup: this.dropOfforPickup,
        active: this.active,
        departureStartDate: this.daterange1 ? this.daterange1[0] : "",
        departureEndDate: this.daterange1 ? this.daterange1[1] : "",
        arrivalStartDate: this.daterange2 ? this.daterange2[0] : "",
        arrivalEndDate: this.daterange2 ? this.daterange2[1] : "",
      };
      getDropOfforPickup(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
        }
        this.loading = false;
      });
    },
    getDropOfforPickupfa() {
      this.page = 1;
      this.getDropOfforPickup();
    },
  },

  created() {
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    getGeneralBasic().then((res) => {
      if (res.status === 200) {
        this.Countries = res.response.Countries;
      }
    });
    //全部飞行路线
    getAirlinesAll().then((res) => {
      if (res.status === 200) {
        this.ticketGroupList = res.response;
      }
    });
    this.getDropOfforPickup();
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    // height: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 0 0 24px 0;
    .screen-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 0 10px;
      .screen-input-box {
        margin-top: 24px;
      }
      .btn-pre {
        margin-top: 24px;
      }
      .btn-add {
        margin-top: 24px;
      }
      .el-input {
        width: 150px;
        margin-right: 24px;
      }
      .el-button {
        margin-right: 24px;
      }
      .el-select {
        width: 150px;
        margin-right: 24px;
      }
      .el-date-editor {
        margin-right: 24px;
      }
      .senior {
        position: absolute;
        width: 100%;
        top: 40px;
        left: 0;
        background: #fefefe;
        border: 1px solid #eae8e8;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgb(234, 232, 232);
        box-sizing: border-box;
        z-index: 999;
        padding: 0 24px 24px 24px;
        border-top: 0;
        box-shadow: 0 7px 18px -12px #bdc0bb;
        .from-boxs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .from-box {
            margin-top: 24px;
            .name {
              margin-bottom: 10px;
            }
            .el-select {
              width: 150px;
            }
            .el-input {
              width: 150px;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        }
      }
    }
    .table-box {
      width: 100%;
      // height: calc(100% - 143px);
      .el-tabs {
        margin-top: 24px;
      }
      .el-table {
        // margin-top: 24px;
        // height: calc(100% - 56px);
        min-height: 500px;
        box-sizing: border-box;
      }
      .el-pagination {
        margin-top: 24px;
      }
      .table-color {
        color: #0076ff;
      }
      .buttons {
        display: flex;
      }
      .fa {
        margin-right: 10px;
        color: #3c8dbc;
        cursor: pointer;
      }
      .fa:hover {
        color: #72afd2;
      }
    }
  }
  .upload-box {
    display: flex;
    height: 34px;
    margin-top: 24px;
    .left {
      width: 100%;
      border: 1px solid #d2d6de;
      border-right: none;
      .name {
        display: flex;
        align-items: center;
        height: 34px;
        color: #555;
        font-size: 14px;
        text-indent: 2px;
      }
    }
    .btn-up {
      padding: 0;
      margin: 0;
      border: none;
      width: 90px;
      flex-shrink: 0;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-color: #3c8dbc;
      border: 1px solid #367fa9;
      color: #fff;
      font-size: 14px;
      .icon {
        margin-right: 5px;
      }
    }
    .disabled {
      cursor: no-drop;
    }
    .btn-up:hover {
      border-color: #204d74;
      background-color: #367fa9;
    }
  }
  .dialog-main {
    border-bottom: 1px solid #f4f4f4;
    padding: 12px 24px 0 24px;
    width: 100%;
    box-sizing: border-box;
    .line-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .from-box {
        display: flex;
        align-items: center;
        width: 330px;
        margin-bottom: 24px;
        .name {
          width: 80px;
          font-size: 16px;
          flex-shrink: 0;
        }
        .el-cascader {
          width: 250px;
        }
        .el-input {
          width: 250px;
        }
      }
    }
  }
  .dialog-footer {
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .el-button {
      height: 34px;
      padding: 0 20px;
      margin-right: 12px;
      margin-left: 0;
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 1px solid #f4f4f4;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}
.tableData2 >>> .el-table__header .cell {
  font-size: 14px !important;
  font-weight: bolder !important;
}

.tableData2 >>> .el-table__empty-block {
  width: 100% !important;
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.card-upload >>> .el-upload-list {
  display: none;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
.card-select >>> .el-input__icon {
  line-height: 34px;
}
</style>
